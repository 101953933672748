"use client";

import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import { Button, CardHeader } from "@nextui-org/react";
import Image, { StaticImageData } from "next/image";
import Image40 from "./image 40.png";
import Image40_1 from "./image 42.png";
import Image44 from "./image 44.png";
import Image44_1 from "./image 44 (1).png";
import Image44_2 from "./image 44 (2).png";
import Image46 from "./image 46.png";
import Image46_1 from "./image 47.png";
import Image46_2 from "./image 47 (1).png";
import Group from "./Group.png";
import Rectangle11 from "./Rectangle 11 (1).png";
import Consulting from "./consulting.png";
import Funnel from "./funnel-alt-1.png";
import Invest from "./invest-monitor.png";
import UserLove from "./user-love.png";
import Grow from "./grow.png";
import Tele from "./Tele.png";
import Square from "./square poll horizontal.png";
import BackgroundImage from "./Rectangle 11.png";
import { Card, CardBody } from "@nextui-org/react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useState } from "react";
// interface Solution {
//   id: number; // Unique identifier for each solution
//   title: string;
//   description: string;
//   buttonText: string;
//   // image: StaticImageData; // Assuming images are imported as strings
//   content: React.ReactNode;
//   feature1: string;
//   iconFeature1: StaticImageData;
//   feature2: string;
//   iconFeature2: StaticImageData;
//   image: any;
//   alt: string;
//   reverse: boolean;
// }

// Data array containing all solutions
const solutionData = {
  title: "Giải pháp Marketing đột phá thúc đẩy tăng trường",
  image: {
    search: Image44,
    lead: Image44_2,
    filter: Image44_1,
  },
  alt: "Mô tả hình ảnh chính",
  cards: [
    {
      cardTitle: "Tìm kiếm khách hàng",
      descriptions: [
        "Đưa ra tập khách hàng lớn về cho doanh nghiệp: lọc khách hàng từ Facebook, cổng thông tin doanh nghiệp. Giúp doanh nghiệp không phụ thuộc vào quảng cáo.",
        "Kết hợp các giải pháp Callbot, Telesales để lọc khách hàng mục tiêu thành khách hàng tiềm năng.",
      ],
      key: "search",
      cardImage: Group,
    },
    {
      cardTitle: "Lead Generation",
      descriptions: [
        "Tìm kiếm khách hàng từ Facebook, cổng thông tin doanh nghiệp và chia chủ động cho nhân viên kinh doanh theo quy tắc tùy chỉnh, giúp đảm bảo sales luôn có khách mới hàng ngày.",
        "Lead từ các nguồn được chia cho sales theo nhiều cơ chế: chia đều, chia theo tỷ lệ,...",
      ],
      key: "lead",
      cardImage: Consulting,
    },
    {
      cardTitle: "Lọc khách hàng tự động",
      descriptions: [
        "Gọi điện tự động tiếp cận hàng ngàn khách hàng trong một giờ bằng giải pháp auto call tích hợp công nghệ AI để lọc khách hàng tự động.",
        "Giải pháp gọi khách hàng mục tiêu hàng loạt để khảo sát và phân loại khách hàng thành từng nhóm: khách hàng quan tâm, khách hàng hẹn gọi lại,...",
      ],
      key: "filter",
      cardImage: Funnel,
    },
  ],
  reverse: false,
};
const solutionData1 = {
  title: "Giải phóng tiềm năng đội ngũ kinh doanh của bạn",
  image: {
    telesale: Image40,
    sales_management: Image40_1,
    leadn_urturing: Image40_1,
  },
  alt: "Mô tả hình ảnh chính",
  cards: [
    {
      cardTitle: "Quản lý bán hàng",
      descriptions: [
        "Tổng hợp dữ liệu khách hàng từ nhiều nguồn về một nguồn duy nhất:",
        "Quản lý thông tin liên hệ, đặc điểm, lịch sử trao đổi trên tất cả kênh bán.",
        "Phân loại theo tập khách hàng thành từng tập để tiện chăm sóc",
        "Quản lý cơ hội bán hàng trên giao diện kanban với nhiều tuỳ biến, bộ lọc được tinh chỉnh theo nhu cầu của từng đội nhóm.",
      ],
      key: "telesale",
      cardImage: Invest,
    },
    {
      cardTitle: "Gọi điện Telesales",
      descriptions: [
        "Đa dạng các chiến dịch gọi điện Telesales: Manual Dial, Preview Dial, Progresssive Dial. Predictive Dial, Hybrid Dial.",
        "Báo cáo đánh giá tiến độ chiến dịch, chi phí cuộc gọi, trạng thái cuộc gọi trong từng khung giờ, thời lượng cuộc gọi.",
        "Sử dụng công nghệ speech-to-text để bóc tách nội dung cuộc gọi và phân tích nội dung bằng AI.",
      ],
      key: "sales_management",
      cardImage: Tele,
    },
    {
      cardTitle: "Lead Nurturing",
      descriptions: [
        "Thông tin khách hàng được lưu trữ tập trung trên một nền tàng. Khách hàng được phân loại thành từng tập để quản lý và chăm sóc.",
        "Nuôi dưỡng từng tập khách hàng tự động và cá nhân hóa bằng các chiến dịch gọi điện tự động Callbot AI Auto Call, ZNS, SMS và Email Marketing.",
      ],
      key: "leadn_urturing",
      cardImage: Grow,
    },
  ],
  reverse: true,
};

const solutionData2 = {
  title: "Chăm sóc khách hàng cũng có thể mang lại doanh thu",
  image: {
    customer_support: Image46,
    customer_care: Image46_1,
    feedback: Image46_2,
  },
  alt: "Mô tả hình ảnh chính",
  cards: [
    {
      cardTitle: "Quản lý hỗ trợ khách hàng",
      descriptions: [
        "Chat đa kênh giúp doanh nghiệp giao tiếp với khách hàng qua tất cả các kênh chỉ với một giao diện duy nhất: Facebook, Zalo, Instagram, Telegram,...",
        "Ticket giúp doanh nghiệp theo dõi và giám sát quá trình hỗ trợ giải quyết các vấn đề và yêu cầu từ khách hàng của bộ phận chăm sóc khách hàng",
      ],
      key: "customer_support",
      cardImage: UserLove,
    },
    {
      cardTitle: "Tự động chăm sóc khách hàng sau bán",
      descriptions: [
        "AI Callbot gọi tự động hoặc ZNS gửi tự động để xác nhận đơn hàng đến lượng lớn khách hàng chỉ với một nút bấm, giúp tiết kiệm 50% chi phí vận hành và 80% thời gian xử lý thông tin và đơn hàng.",
        "AI Callbot và ZNS cũng có thể tự động nhắc lịch hẹn cho hàng khách hàng: nhắc lịch sự kiện, nhắc lịch khám,...",
      ],
      key: "customer_care",
      cardImage: Consulting,
    },
    {
      cardTitle: "Lấy feedback khách hàng",
      descriptions: [
        "Callbot AI gọi tự động khảo sát ý kiến cùng lúc đến hàng ngàn khách hàng khi trải nghiệm dịch vụ.",
        "ZNS Đánh giá dịch vụ (ZNS Rating Template) tự động gửi đến khách hàng cuối đã trải nghiệm sản phẩm/dịch vụ của doanh nghiệp để đánh giá độ hài lòng của khách hàng. ",
      ],
      key: "feedback",
      cardImage: Square,
    },
  ],
  reverse: false,
};

interface CardProps {
  title: string;
  icon: string; // Path to the icon image
  description: string[];
  hoveredCard: string;
  setHoveredCard: (value: string) => void;
  cardKey: string;
}
interface SolutionItemProps {
  title: string;
  image: any;
  alt: string;
  cards: any;
  reverse: boolean;
  hoveredCard: string;
  setHoveredCard: (text: string) => void;
}

const SolutionItem: React.FC<SolutionItemProps> = ({
  title,
  image,
  alt,
  cards,
  reverse,
  hoveredCard,
  setHoveredCard,
}) => {
  const mainImage = image?.[hoveredCard];
  return (
    <div
      className={`row justify-between mt-10 ${reverse ? "flex-row-reverse" : ""}`}>
      <div className="col-12 col-md-6">
        <h4 className="font-semibold text-2xl text-adahubSecondary">{title}</h4>
        <p className="mt-3 whitespace-pre-line text-[#2D68F8] flex gap-2 text-[12px] cursor-pointer">
          Đăng ký tư vấn miễn phí <ArrowRightOutlined />
        </p>
        {cards.map((item: any) => (
          <div key={item.key}>
            <div
              className={`transition-all duration-500 ${
                hoveredCard === item.key ? "hidden" : "block"
              } mt-3 whitespace-pre-line`}
              onClick={() => setHoveredCard(item.key)}>
              {/* Hiển thị khi chưa click */}
              <div className="flex gap-3 items-center text-adahubSecondary text-xl mb-3 cursor-pointer">
                <Image src={item.cardImage} alt="" />
                <h2 className="font-bold">{item.cardTitle}</h2>
              </div>
            </div>
            {/* Nội dung hiển thị khi click */}
            {hoveredCard === item.key && (
              <Card className="mt-3 whitespace-pre-line">
                <CardHeader className="flex gap-3 items-center text-xl">
                  <Image src={item.cardImage} alt="" />
                  <h2 className="text-[#1C3FB7] font-bold">{item.cardTitle}</h2>
                </CardHeader>
                <CardBody>
                  {item.descriptions.map((desc, index) => (
                    <p key={index} className="mb-6">
                      {desc}
                    </p>
                  ))}
                </CardBody>
              </Card>
            )}
          </div>
        ))}
      </div>

      <div className="col-12 relative col-md-6 overflow-hidden md:mt-0">
        <Image
          placeholder="blur"
          src={BackgroundImage}
          alt="Background"
          className="-z-10 w-full max-h-[600px]"
        />
        <div
          style={{
            transform: reverse ? "translate(3%, -43%)" : "translate(-3%, -43%)",
          }}
          className={`position-absolute top-[50%] ${reverse ? "left-0" : "right-0"}  w-[90%] h-[90%]`}>
          <Image src={mainImage} alt={alt} />
        </div>
      </div>
    </div>
  );
};

const SolutionsHomepage = () => {
  const [hoverStates, setHoverStates] = useState("search");
  const [hoverStates1, setHoverStates1] = useState("telesale");
  const [hoverStates2, setHoverStates2] = useState("customer_support");
  return (
    <ContentContainer>
      <div className="full-size row items-center justify-center mt-16">
        <div className="text-center">
          <h3 className="font-semibold text-3xl text-adahubSecondary">
            Giải pháp tối ưu hóa hoạt động kinh doanh
          </h3>
          <h3 className="font-semibold text-3xl text-adahubSecondary">
            cho doanh nghiệp của bạn
          </h3>
        </div>
        <div className="flex flex-col flex-wrap gap-5">
          <SolutionItem
            alt={solutionData.alt}
            cards={solutionData.cards}
            hoveredCard={hoverStates}
            image={solutionData.image}
            reverse={solutionData.reverse}
            setHoveredCard={setHoverStates}
            title={solutionData.title}
          />
          <SolutionItem
            alt={solutionData1.alt}
            cards={solutionData1.cards}
            hoveredCard={hoverStates1}
            image={solutionData1.image}
            reverse={solutionData1.reverse}
            setHoveredCard={setHoverStates1}
            title={solutionData1.title}
          />
          <SolutionItem
            alt={solutionData2.alt}
            cards={solutionData2.cards}
            hoveredCard={hoverStates2}
            image={solutionData2.image}
            reverse={solutionData2.reverse}
            setHoveredCard={setHoverStates2}
            title={solutionData2.title}
          />
        </div>
      </div>
    </ContentContainer>
  );
};

export default SolutionsHomepage;
